import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Test Week`}</em></strong></p>
    <p>{`Max Height Box Jump 1-1-1-1-1 to 1RM`}</p>
    <p>{`Deadlift 1-1-1-1-1 to 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9-6-3 reps each of:`}</p>
    <p>{`Deadlifts (225/155)`}</p>
    <p>{`Box Jumps (30/24)`}</p>
    <p><em parentName="p">{`*`}{`compare to 10/25/16`}</em></p>
    <p><strong parentName="p">{`Bonus Tests: `}</strong></p>
    <p>{`A. 50 Calorie Bike for time.`}</p>
    <p>{`B. 75 Calorie Ski Erg for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      